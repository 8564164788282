@font-face {
    font-family: 'ignsearchlocator';
    src: url('../fonts/ignsearchlocator.eot?ym1k95');
    src: url('../fonts/ignsearchlocator.eot?ym1k95#iefix') format('embedded-opentype'),
        url('../fonts/ignsearchlocator.ttf?ym1k95') format('truetype'),
        url('../fonts/ignsearchlocator.woff2?ym1k95') format('woff2'),
        url('../fonts/ignsearchlocator.woff?ym1k95') format('woff'),
        url('../fonts/ignsearchlocator.svg?ym1k95#ignsearchlocator') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.m-panel.ign-searchlocator-panel [class^="icon-"]:before,
.m-panel.ign-searchlocator-panel [class*=" icon-"]:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'ignsearchlocator' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.m-panel.ign-searchlocator-panel .icon-help:before {
    content: '\e800';
}

.m-panel.ign-searchlocator-panel .icon-locate:before {
    content: '\e801';
    position: relative;
    bottom: -1px;
}

.m-panel.ign-searchlocator-panel .icon-ignsearch:before {
    content: '\e807';
}

.m-panel.ign-searchlocator-panel .icon-remove:before {
    content: '\e93e';
}

.m-panel.ign-searchlocator-panel .icon-coordinates:before {
    content: '\e94a';
    position: relative;
    bottom: -1px;
}

.m-panel.ign-searchlocator-panel .icon-info:before {
    content: '\e94b';
}

.m-panel.ign-searchlocator-panel .icon-position:before {
    content: '\e95a';
}

.m-panel.ign-searchlocator-panel .icon-catastro:before {
    content: '\e99f';
}