.ign-searchlocator-panel.opened .m-panel-btn.g-cartografia-flecha-derecha,
.ign-searchlocator-panel.opened .m-panel-btn.g-cartografia-flecha-izquierda {
    color: white !important;
    background-color: #71a7d3 !important;
}


.ign-searchlocator-panel {
    order: -100;
}

#div-contenedor.m-ignsearchlocator .search-panel {
    order: -100;
}

.m-ignsearchlocator .search-panel.collapsed {
    height: 40px;
}



.m-ignsearchlocator .search-panel {
    background-color: white;
}

/* Loading animation */

#m-ignsearchlocator-results {
    align-items: center;
    background-color: white;
    display: flex;
    position: absolute;
}

div.m-area.m-bottom>div>div #div-contenedor>#m-ignsearchlocator-results {
    bottom: 40px;
}

#m-ignsearchlocator-results::before {
    margin: 0 auto;
    -webkit-animation: mloading 1s linear infinite;
    -moz-animation: mloading 1s linear infinite;
    animation: mloading 1s linear infinite;
}

/* Other */

.m-areas>div.m-area>div.m-panel.ign-searchlocator-panel.opened {
    background-color: transparent !important;
    z-index: 2000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2), 0 -1px 0 rgba(0, 0, 0, .02);
}

.m-ignsearchlocator .search-panel {
    height: 40px;
}

.m-ignsearchlocator #m-ignsearchlocator-results #m-ignsearchlocator-results-list,
.m-ignsearchlocator #m-ignsearchlocator-results #m-ignsearchlocator-recent-results-list {
    list-style-position: outside;
    list-style-type: none;
    overflow: hidden;
    padding-left: 0.5em;
    width: 100%;
}

/* former text color: #7A7A73; */

.m-ignsearchlocator #m-ignsearchlocator-results #m-ignsearchlocator-results-list li,
.m-ignsearchlocator #m-ignsearchlocator-results #m-ignsearchlocator-recent-results-list li {
    color: black;
    line-height: 15px;
    margin: 5px 0;
    overflow-wrap: break-word;
    padding-top: 3px;
    white-space: pre-line;
}

.m-ignsearchlocator #m-ignsearchlocator-results #m-ignsearchlocator-results-list li+span,
.m-ignsearchlocator #m-ignsearchlocator-results #m-ignsearchlocator-recent-results-list li+span {
    color: #7A7A73;
}

.m-ignsearchlocator #m-ignsearchlocator-results #m-ignsearchlocator-results-list li:hover,
.m-ignsearchlocator #m-ignsearchlocator-results #m-ignsearchlocator-recent-results-list li:hover {
    background-color: #71a7d3;
    color: white;
    cursor: pointer;
    padding-left: 1em;
}

#m-ignsearchlocator-search-input {
    width: 177px;
    border: 1px solid #7a7a73;
    margin-right: -2px;
}

button#m-ignsearchlocator-locate-button {
    color: #7A7A73;
    font-size: 1.4em;
}

#m-ignsearchlocator-clear-button,
#m-ignsearchlocator-xylocator-button,
#m-ignsearchlocator-parcela-button,
#m-ignsearchlocator-search-refCatastral,
#m-ignsearchlocator-locate-button {
    background-color: white;
    border: none;
    width: 35px;
    height: 40px;
    margin-top: -0.5px;
    text-align: center;
}

#m-ignsearchlocator-xylocator-button span {
    position: relative;
    right: 2px;
}

#m-ignsearchlocator-locate-button {
    font-family: g-plugin-ignsearch;
}

#m-ignsearchlocator-locate-button:focus {
    outline: none;
}


.m-ignsearchlocator .search-panel button:hover {
    cursor: pointer;
}

#m-ignsearchlocator-parcela-button {
    color: #7A7A73;
    font-size: 1.4em;
}

#m-ignsearchlocator-parcela-button span {
    position: relative;
    top: 1px;
    right: 1px;
}

#m-ignsearchlocator-search-refCatastral {
    color: #7A7A73;
    font-size: 1.4em;
}

#m-ignsearchlocator-search-refCatastral span {
    position: relative;
    top: 2px;
}


#m-ignsearchlocator-xylocator-button {
    color: #7A7A73;
    font-size: 1.4em;
}

#m-ignsearchlocator-clear-button {
    color: #7A7A73;
    font-size: 1.4em;
}

#m-ignsearchlocator-clear-button span {
    position: relative;
    bottom: 1px;
    right: 2px;
}

div.m-panel.ign-searchlocator-panel.collapsed button.m-panel-btn.icon-ignsearch::before {
    vertical-align: top;
    font-size: 24px;
}

.m-ignsearchlocator .search-panel .icon-remove::before {
    vertical-align: bottom;
}

.ignsearchlocator-popup {
    width: 250px;
    margin-bottom: 2px;
}

.ignsearchlocator-popup b {
    margin-right: 8px;
}

.m-ignsearchlocator>.search-panel>button#m-ignsearchlocator-xylocator-button,
.m-ignsearchlocator>.search-panel>button#m-ignsearchlocator-parcela-button,
.m-ignsearchlocator>.search-panel>button#m-ignsearchlocator-search-refCatastral,
.m-ignsearchlocator>.search-panel>button#m-ignsearchlocator-locate-button,
.m-ignsearchlocator>.search-panel>button#m-ignsearchlocator-clear-button {
    vertical-align: bottom;
}


#m-ignsearchlocator-xylocator-button,
#m-ignsearchlocator-parcela-button,
#m-ignsearchlocator-search-refCatastral,
#m-ignsearchlocator-clear-button:focus {
    outline: none;
}

/*********************
       XYLOCATOR
*********************/

#div-contenedor.m-ignsearchlocator .m-xylocator-container {
    width: 100%;
}

#div-contenedor.m-ignsearchlocator #m-xylocator-limpiar {
    float: right;
    margin-right: '1rem';
}

#m-xylocator-loc {
    background-color: #71a7d3;
    border: none;
    color: white;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container div.button {
    align-items: center;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    display: flex;
    background-color: #eaeaea;
    padding: 10px 0px;
}

input#LON {
    width: 23.8%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 0px 3px 5px;
    background-color: #fdfdfd;
    box-shadow: 1px 1px 3px 0px #999;
    border-radius: 0px;
    margin: 0px;
    margin-bottom: 5px;
    margin-left: 5px;
}

input#LAT {
    width: 23.8%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 0px 3px 5px;
    background-color: #fdfdfd;
    box-shadow: 1px 1px 3px 0px #999;
    border-radius: 0px;
    margin: 0px;
    margin-bottom: 5px;
    margin-left: 5px;

}

input#UTM-X {
    width: 38%;
}

input#UTM-Y {
    width: 38%;
}

#m-xylocator-dms>div {
    display: flex;
    min-width: 300px;
    margin-top: -25px;
}

#m-xylocator-dms>div>div {
    height: 67px;
    display: flex;
    align-items: center;
}

#m-xylocator-dms>div>div>input[type="number"] {
    width: 2.5rem;
}

#m-xylocator-dms>div>div>span {
    font-size: 24px;
    margin-left: 2px;
    margin-right: 10px;
}

/*********************
     TITLES
*********************/
#div-contenedor.m-ignsearchlocator .m-xylocator-container>div.m-xylocator-panel div.title {
    background-color: #71a7d3 !important;
    line-height: 40px;
    padding: 0;
    padding-left: 10px;
    height: 39px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    font-size: 16px;
    color: white !important;
    text-align: center;
}

/*********************
     COLUMN & CONTENT COLUMN
*********************/
#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div>span {
    width: 32%;
    /* display: inline-block; */
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel>.m-xylocator-lonlat {
    width: 32%;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div>select {
    width: 65%;
    appearance: none;
    padding: 2px 0px 3px 5px;
    box-shadow: 1px 1px 3px 0px #999;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel>.m-xylocator-lonlat>div>select {
    width: 65%;
    appearance: none;
    padding: 2px 0px 3px 5px;
    box-shadow: 1px 1px 3px 0px #999;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div>input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 0px 3px 5px;
    background-color: #fdfdfd;
    box-shadow: 1px 1px 3px 0px #999;
    border-radius: 0px;
    margin: 0px;
    margin-bottom: 5px;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel>.m-xylocator-lonlat>div>input {
    width: 63%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 0px 3px 5px;
    background-color: #fdfdfd;
    box-shadow: 1px 1px 3px 0px #999;
    border-radius: 0px;
    margin: 0px;
    margin-bottom: 5px;
}


input#m-searchParamsParcela-input {
    width: 20%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 0px 3px 5px;
    background-color: #fdfdfd;
    box-shadow: 1px 1px 3px 0px #999;
    border-radius: 0px;
    margin: 0px;
    margin-bottom: 5px;
    margin-top: 10px;
    float: right;
}

input#m-searchParamsPoligono-input {
    width: 20%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 0px 3px 5px;
    background-color: #fdfdfd;
    box-shadow: 1px 1px 3px 0px #999;
    border-radius: 0px;
    margin: 0px;
    margin-bottom: 5px;
    margin-right: 2%;
}

input#m-refCatastral-input {
    width: 20%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 0px 3px 5px;
    background-color: #fdfdfd;
    box-shadow: 1px 1px 3px 0px #999;
    border-radius: 0px;
    margin: 0px;
    margin-bottom: 5px;
    margin-right: 6px;
}

p.parcela--input {
    width: 90px;
    display: inline-table;
}

p.refCastastrl--input {
    width: 100%;
    display: inline-table;
}


#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas {
    width: 92%;
    padding-left: 4%;
    padding-right: 4%;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel>.m-xylocator-lonlat {
    width: 92%;
    padding-left: 4%;
    padding-right: 4%;
}


#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div {
    margin-bottom: 2%;
    display: table;
    width: 100%;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel>.m-xylocator-lonlat>div {
    margin-bottom: 4%;
    margin-top: 5%;
    display: table;
    width: 100%;
}

/***************************
DROPDOWN ****************************/
#div-contenedor.m-ignsearchlocator .dropdown {
    position: relative;
    display: inline-block;
    width: 60%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 0px 3px 5px;
    box-shadow: 1px 1px 3px 0px #999;
}

#div-contenedor.m-ignsearchlocator #m-xylocator-loc {
    background-color: #fff;
    padding: 5px;
    cursor: pointer;
    display: block;
    font-family: Muli,
        sans-serif !important;
    border-radius: 0px;
    border: 1px solid rgba(0, 0, 0, .4);
    font-size: 15px;
    -webkit-transition: opacity .25s ease 0s;
    transition: opacity .25s ease 0s;
    color: black;
    height: 28px;
}

#div-contenedor.m-ignsearchlocator #m-xylocator-limpiar {
    background-color: #71a7d3;
    border: none;
    color: white;
    margin: 10px;
    padding: 3px 3px 0 3px;
}


/****************************
     FIREFOX HACKS
****************************/
@-moz-document url-prefix() {

    #div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div select {
        border: 1px solid rgb(167, 160, 160);
        padding: 1px 1px 2px 3px;
        /* width: 85% !important; */
    }

    #div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div span {
        width: 33%;
        display: inline-block;
    }

    #div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel>.m-xylocator-lonlat>div>span {

        width: 33%;
        display: inline-block;
    }

    #m-searchParamsProvincia-select {
        font-size: 13px;
        width: 58% !important;
    }

    #m-searchParamsMunicipio-select {
        font-size: 13px;
        width: 58% !important;
    }

    #div-m-xylocator-coordinatesSystemParcela p.parcela--input {
        display: inline-block;
    }

    #div-m-xylocator-coordinatesSystemRefCatastral p.refCastastrl--input {
        display: inline-block;
    }


    input#LON {
        width: 22%;
    }

    input#LAT {
        width: 22%;
    }

    /*#m-xylocator-dms>div>div>input[type="number"] {
        width: 2.2rem;
    }*/

    input#UTM-X {
        width: 36%;
    }

    input#UTM-Y {
        width: 36%;
    }

    input#m-searchParamsPoligono-input {
        width: 17% !important;
    }

    input#m-refCatastral-input {
        width: 17% !important;
    }

    input#m-searchParamsParcela-input {
        width: 17% !important;
    }

    #m-provin {

        width: 91px !important;

    }

    #m-munici {

        width: 91px !important;

    }

    #m-informa {
        font-size: 11px !important;
    }

}

/****************************
     IE HACK
****************************/
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    #div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div>select::-ms-expand {
        display: none;
    }

    #div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div>select {
        border: 1px solid rgb(167, 160, 160);
    }
}



/***************
    BUTTON
***************/
#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel section>div.button>button {
    background-color: #fff;
    padding: 5px;
    cursor: pointer;
    display: block;
    font-family: Muli, sans-serif !important;
    border-radius: 0px;
    border: 1px solid rgba(0, 0, 0, .4);
    font-size: 13px;
    -webkit-transition: opacity .25s ease 0s;
    transition: opacity .25s ease 0s;
    color: black;
    height: 28px;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel div.button {
    align-items: center;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    display: flex;
    background-color: #eaeaea;
    padding: 10px 0px;
}

/***************
       PANEL
***************/
.m-areas>div.m-area>div.m-panel.m-xylocator.collapsed {
    max-height: 40px;
}

.m-areas>div.m-area>div.m-panel.m-xylocator>button.m-panel-btn {
    position: absolute;
    background-color: #71a7d3;
    color: #fff;
    z-index: 999999;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
}

.m-areas>div.m-area>div.m-panel.m-xylocator.left>button.m-panel-btn {
    right: 0;
}

.m-areas>div.m-area>div.m-panel.m-xylocator.collapsed>button.m-panel-btn {
    position: inherit;
    color: white;
    left: 0;
    box-shadow: none;
}

.m-areas>div.m-area>div.m-panel.m-xylocator.opened {
    border-top-left-radius: 0;
    background-color: #f4f4f4;
}

#div-contenedor.m-ignsearchlocator .m-xylocator-container .m-xylocator-panel {
    margin: 0;
    top: 0px;
    right: 0px;
    max-height: 100%;
    text-align: center;
    background-color: #fffbfb;
}


#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div>span {
    display: inline;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div>select {
    display: block;
    width: 80%;
    margin-top: 3%;
}


#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel>.m-xylocator-lonlat>div>input {
    margin-top: 3%;
    width: 60%;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel>.m-xylocator-lonlat>div#m-xylocator-latlon>input#LON {
    margin-left: 5px;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel>.m-xylocator-lonlat>div#m-xylocator-latlon>input#LAT {
    margin-left: 15px;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel>.m-xylocator-lonlat>div>span {
    display: inline;
}

#div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel>.m-xylocator-lonlat>div>select {
    display: block;
    width: 100%;
    margin-top: 3%;
}

.m-areas>div.m-area.m-bottom.m-right>div.m-panel.ign-searchlocator-panel>div.m-panel-controls {
    margin-left: 40px;
}


.m-areas>div.m-area.m-bottom.m-left>div.m-panel.ign-searchlocator-panel>div.m-panel-controls {
    margin-right: 40px;
}

.m-areas>div.m-area.m-bottom.m-right>div.m-panel>div.m-panel-controls>div.m-control.m-container.m-ignsearchlocator {
    width: 346px;
}

.m-areas>div.m-area.m-bottom.m-left>div.m-panel>div.m-panel-controls>div.m-control.m-container.m-ignsearchlocator {
    width: 346px;
}

.m-areas>div.m-area>div.m-panel>div.m-panel-controls>div.m-control.m-container.m-ignsearchlocator {
    display: block;
}

/****************************
 INTERNET EXPLORER HACKS
****************************/
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    div.search-panel>input.m-input-search {
        width: 75% !important;
    }

    div.search-panel>input.m-input-search::-ms-clear {
        display: none;
    }

    div#m-searching-result-searchRC,
    div#m-searching-result-searchParams {
        padding-left: 44%;
    }

    div.m-control.m-referencelocatorcatastro-container.shown {
        display: block !important;
    }
}

@media only screen and (max-width: 600px) {

    input#m-searchParamsPoligono-input {
        width: 20%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 2px 0px 3px 5px;
        background-color: #fdfdfd;
        box-shadow: 1px 1px 3px 0px #999;
        border-radius: 0px;
        margin: 0px;
        margin-bottom: 5px;
        margin-top: 10px !important;
        float: right !important;
    }

    .m-panel.ign-searchlocator-panel.opened.no-collapsible {
        left: calc(50vw - 210px) !important;
        position: initial !important;
    }

    .m-popup.m-collapsed.m-no-animation {
        width: 99% !important;
    }

    #div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel section>div.button>button {
        font-size: 10.9px;
    }

    /* .m-areas>div.m-area.m-top>div.m-panel>div.m-panel-controls>div.m-control.m-container.m-ignsearchlocator {
        width: 305px;
    } */


    .m-areas>div.m-area.m-bottom>div.m-panel>div.m-panel-controls>div.m-control.m-container.m-ignsearchlocator {
        width: 260px;
    }

    .m-areas>div.m-area.m-bottom.m-right>div.m-panel>div.m-panel-controls>div.m-control.m-container.m-ignsearchlocator {
        width: 261px;
    }

    .m-areas>div.m-area.m-bottom.m-left>div.m-panel>div.m-panel-controls>div.m-control.m-container.m-ignsearchlocator {
        width: 261px;
    }

    .m-ignsearchlocator #m-ignsearchlocator-search-input {
        max-width: 35%;
    }

    div#m-ignsearchlocator-results {
        width: 75% !important;
        min-width: 75% !important;
    }

    select#m-searchParamsProvincia-select {
        width: 100% !important;
    }

    select#m-searchParamsMunicipio-select {
        width: 100% !important;
    }

    #div-contenedor.m-ignsearchlocator .dropdown {
        width: 93%;
    }

    p.parcela--input {
        margin-left: 0px !important;
        width: auto;
    }

    p.refCastastrl--input {
        margin-left: 0px !important;
    }

    #div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div#m-xylocator-latlon>input#LON {
        width: 50%;
    }

    #div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div#m-xylocator-latlon>input#LAT {
        width: 50%;
        margin-left: 11px;
    }

    #div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div>select {
        width: 100%;
        font-size: 12.5px;
    }

    #div-contenedor.m-ignsearchlocator .m-xylocator-container>div.m-xylocator-panel div.title {
        padding-left: 0px;
        font-size: 14px;
    }

    .m-ignsearchlocator>.search-panel>button#m-ignsearchlocator-xylocator-button,
    .m-ignsearchlocator>.search-panel>button#m-ignsearchlocator-parcela-button,
    .m-ignsearchlocator>.search-panel>button#m-ignsearchlocator-search-refCatastral,
    .m-ignsearchlocator>.search-panel>button#m-ignsearchlocator-locate-button,
    .m-ignsearchlocator>.search-panel>button#m-ignsearchlocator-clear-button {
        vertical-align: bottom;
    }

    /* .m-areas>div.m-area.m-top>div.m-panel.ign-searchlocator-panel.opened {
        width: 85% !important;
    } */

    #div-contenedor.m-ignsearchlocator div.m-xylocator-container>div.m-xylocator-panel .m-xylocator-datas>div>input {

        width: 33.9%;
        margin-right: 5px;
    }

    input#m-searchParamsPoligono-input {
        width: 60% !important;
    }

    input#m-refCatastral-input {
        width: 60%;
    }

    input#m-searchParamsParcela-input {
        width: 60%;
    }


    #m-xylocator-dms>div>div>input[type="number"] {
        width: 1.5rem;
    }

    #m-xylocator-dms>div>div {
        width: 45px;
    }


    #div-contenedor>div.search-panel {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

}

@media only screen and (max-width: 398px) {
    .m-ignsearchlocator #m-ignsearchlocator-search-input {
        width: 25%;
    }
}


/* MICROSOFT EDGE HACK */

@supports (-ms-ime-align:auto) {
    input#m-searchParamsPoligono-input {
        width: 19% !important;
    }

    input#m-refCatastral-input {
        width: 19% !important;
    }

    input#m-searchParamsParcela-input {
        width: 19% !important;
    }

    #m-informa {
        display: inline !important;
    }
}

.m-ignsearchlocator-noresults {
    text-align: center;
    width: 100%;
}

.m-xylocator-help-button {
    position: absolute;
    left: 240px;
    top: 76px;
}

@-moz-document url-prefix() {
    .m-xylocator-help-button {
        left: 252px;
    }
}

.m-xylocator-help-button>a {
    font-size: 24px;
    color: #71a7d3 !important;
    text-decoration: none;
}

.m-search-tabs>input[type="radio"] {
    display: none;
}

.m-search-tabs .m-search-tab-panels {
    display: none;
}

.m-search-tabs>input:first-child:checked~.m-search-tab-content>.m-search-tab-panels:first-child,
.m-search-tabs>input:nth-child(3):checked~.m-search-tab-content>.m-search-tab-panels:nth-child(2),
.m-search-tabs>input:nth-child(5):checked~.m-search-tab-content>.m-search-tab-panels:nth-child(3),
.m-search-tabs>input:nth-child(7):checked~.m-search-tab-content>.m-search-tab-panels:nth-child(4),
.m-search-tabs>input:nth-child(9):checked~.m-search-tab-content>.m-search-tab-panels:nth-child(5),
.m-search-tabs>input:nth-child(11):checked~.m-search-tab-content>.m-search-tab-panels:nth-child(6) {
    display: block;
}

.m-search-tabs>label {
    display: inline-block;
    position: relative;
    padding: 10px 16px;
    border: 1px solid #ccc;
    border-bottom: 0;
    cursor: pointer;
    margin: 0 -3px;
}

.m-search-tabs>label::after {
    content: "";
    position: absolute;
    bottom: 5px;
    height: 3px;
    background: #8d8d8d;
    left: 10px;
    right: 10px;
}

.m-search-tabs>label:hover,
.m-search-tabs>input:focus+label {
    color: #71a7d3;
}

.m-search-tabs>label:hover::after,
.m-search-tabs>input:focus+label::after,
.m-search-tabs>input:checked+label::after {
    background: #71a7d3;
}

.m-search-tabs>input:checked+label {
    border-color: #ccc;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
}

.m-search-tab-panels {
    border-top: 1px solid #ccc;
}